import { useState, useRef } from 'react';
import { debounce } from 'lodash'

import { 
    getPharmacies,
    getPharmacyDetails
} from '../api/PharmacyAPI'

export const usePharmacies = (pharmacyId=null) => {
    const [pharmacySearchResults, setPharmacySearchResults] = useState([])
    const [pharmaciesLoading, setPharmaciesLoading] = useState(false)
    const [selectedPharmacy, selectPharmacy] = useState(null)

    const abortController = useRef(null)

    const fetchPharmacy = async pharmacyId => {
        if (!pharmacyId) return

        try {
            const { data: { pharmacy } } = await getPharmacyDetails(pharmacyId)
            return pharmacy
        } catch (e) {
            console.log(e)
            throw new Error('Error fetching pharmacy')
        }
    }

    const searchPharmacies = debounce(async (pharmacyName) => {
        selectPharmacy(null)

        if (pharmacyName?.length < 3) return

        setPharmaciesLoading(true)
        setPharmacySearchResults([])

        abortController.current?.abort()
        abortController.current = new AbortController()

        try {
            getPharmacies(pharmacyName)
                .then(({ data: { pharmacies }, config: { body } }) => {
                    if (!abortController.current?.signal || !body?.signal.aborted) {
                         setPharmacySearchResults(pharmacies)
                         setPharmaciesLoading(false)
                    }
                })
        } catch (e) {
            console.log(e)
        } finally {
            setPharmaciesLoading(false)
        }
    }, 500)

    const selectPharmacyByName = (pharmacyName) => {
        if (!pharmacyName) return

        const pharmacyMatch = pharmacySearchResults.find(pharmacy => pharmacy.name === pharmacyName)
        if (!pharmacyMatch) return

        selectPharmacy(pharmacyMatch)
    } 

    return { 
        pharmacySearchResults,
        pharmaciesLoading,
        fetchPharmacy,
        selectPharmacyByName,
        selectPharmacy,
        selectedPharmacy,
        searchPharmacies
    }
}